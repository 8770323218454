
<template>
  <div id="app">
    <div class="background">
      <nav class="navbar">
 
        <!-- Burger slide button on the left -->
        <div class="navbar-left">
          <Slide class="BurgerSlide">
            <div class="burger-menu-content" :width="auto" :margin-left="0" :text-align="left" >
              <router-link class="burgerlink" to="/">Home</router-link>
              <router-link class="burgerlink" to="/3dprinting">{{ $t('3D-printen-router') }}</router-link>
              <router-link class="burgerlink" to="/Advies">{{ $t('Advies-router') }}</router-link>
              <router-link class="burgerlink" to="/Cursussen">{{ $t('Cursussen-router') }}</router-link>
              <router-link class="burgerlink" to="/Prints">{{ $t('Prints-router') }}</router-link>
              <router-link class="burgerlink" to="/Zwangerschap">{{ $t('Zwangerschap-scans-en-prints-router') }}</router-link>
              <router-link class="burgerlink" to="/Bustes">{{ $t('Bustes-router') }}</router-link>
              <router-link class="burgerlink" to="/3Dscannen">{{ $t('3D-Scannen-router') }}</router-link>
              <router-link class="burgerlink" to="/Assembleren">{{ $t('Assembleren-3D-printers-router') }}</router-link>
              <router-link class="burgerlink" to="/Onderhoud">{{ $t('Onderhoud-3D-printers-router') }}</router-link>
              <router-link class="burgerlink" to="/ContactPage">{{ $t('Contact-pagina-router') }}</router-link>
            </div>
          </Slide>
        </div>
 
       
         
        
        <!-- Logo in the center -->
        <div class="navbar-logo">
          <router-link to="/"><img class="logo" src="./assets/Wesley.png" alt="logo"></router-link>
        </div>
 
        <!-- Language buttons on the right -->
        <div class="language-container">
          <div class="language-buttons">
            <button @click="changeLanguage('nl')">
              <img :src="require('@/assets/netherlands.png')" alt="Nederlands" class="flag-icon">
            </button>
            <button @click="changeLanguage('en')">
              <img :src="require('@/assets/united-kingdom.png')" alt="English" class="flag-icon">
            </button>
            <button @click="changeLanguage('de')">
              <img :src="require('@/assets/germany.png')" alt="Deutsch" class="flag-icon">
            </button>
          </div>
        </div>
 
      </nav>
      <router-view />
    </div>
  </div>
</template>
 
 
<script>
import { Slide } from 'vue3-burger-menu';
 
export default {
  name: 'App',
  components: {
    Slide,
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    }
  }
};
</script>
 
<style scoped>

.background {
  background: url('/src/assets/back1.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh; /* Full height */
  /* border-radius: 10px; */
  position: relative;
  background-attachment: fixed; /* Key property for parallax effect */
}
.navbar {
  background-color: rgb(75, 73, 70);
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* border-radius: 10px; */
  flex-wrap: wrap;
  margin: 0 0 0 0;
}

 
.navbar-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
 
.navbar-logo {
  position: absolute; /* Absolute positionering */
  left: 50%; /* Gecentreerd ten opzichte van de navbar */
  transform: translateX(-50%); /* Horizontaal gecentreerd */
  text-align: center;
}

nav a.router-link-exact-active {
color: #385146;
}

.navbar a {
color: rgb(255, 255, 255);
text-decoration: none;
font-size: 1.2rem;
}
.language-container {
  padding: 5px;
  border-radius: 8px;
  border: 2px solid #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #f0f0f0;
}
 
.language-buttons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
 
.flag-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
 
.logo {
  max-height: 40px;
  padding: 5px;
  border-radius: 10px;
  background-color: #ffffff;
  scale: 150%;
}
 
.burgerlink {
  color: #ffffff; /* Witte tekstkleur */
  text-decoration: none; /* Geen onderstreping */
  transition: transform 0.3s ease, font-size 0.3s ease, color 0.3s ease; /* Smooth animatie voor groei, beweging en kleurverandering */
  font-size: 18px; /* Beginlettergrootte */
}
 
.burgerlink:hover {
  font-size: 20px; /* Iets grotere tekst bij hover */
}
 
.burgerlink:active {
  font-size: 22px; /* Nog groter bij klikken */
  transform: translateX(10px); /* Schuif 10px naar rechts bij klikken */
}
 
.router-link-active {
  color: #d3d3d3; /* Lichtgrijze kleur voor de actieve link */
}
.BurgerSlide{
  text-decoration-color: rgb(255, 255, 255);
  /* background-color: rgb(253, 253, 253); */
  text-align: left;
}

.BurgerSlide .bm-burger-button{
  /* text-decoration-color: rgb(255, 255, 255); */
  background-color: rgb(253, 253, 253);
  /* text-align: left; */
}
 
.burger-menu-content {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-decoration-color: #fff;
  color: #f1dd00;
}
 
.bm-item-list {
  color: #f1dd00;
  margin-left: 0;
  font-size: 20px;
  background-color: rgb(255, 255, 255);
}
.bm-burger-button {
  background-color: #fff; /* White color for visibility */
  height: 4px; /* Thickness of the bars */
  width: 50px; /* Width of the bars */
  border-radius: 2px;
  margin: 6px 0; /* Space between the bars */
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
  }
 
  .language-buttons {
    flex: 1;
    justify-content: flex-end;
  }
 
  .navbar-left {
    flex: 1;
    justify-content: flex-start;
  }
 
  .navbar-logo {
    flex: 1;
    text-align: center;
  }
}
/* CSS Reset to remove default browser spacing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevents horizontal scroll and white border */
}

/* Ensure footer and navbar don't add extra margin or padding */
footer, nav {
  margin: 0;
  padding: 0;
}

/* Additional styling as needed */
.background {
  background: url('/src/assets/back1.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh; /* Full height */
}

</style> 